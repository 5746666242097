<template>
  <b-sidebar
    id="add-new-device-sidebar"
    :visible="isAddNewDeviceSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-device-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Adicionar Dispositivo
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
        
        <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Cód/Nome"
            rules="required|alpha-num"
          >
            <b-form-group
              label="Cód/Nome"
              label-for="cod-name"
            >
              <b-form-input
                id="cod-name"
                v-model="blankdeviceData.Name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Freezer Laticínios"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Device Model -->
          <validation-provider
            #default="validationContext"
            name="model"
            rules="required"
          >
            <b-form-group
              label="Modelo"
              label-for="model"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="blankdeviceData.Model"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="deviceModels"
                value="value"
                label="text"
                :clearable="false"
                input-id="model"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Serial -->
          <validation-provider
            #default="validationContext"
            name="Serial Number"
            rules="required|alpha-num"
          >
            <b-form-group
              label="Número Série"
              label-for="serial-number"
            >
              <b-form-input
                id="serial-number"
                v-model="blankdeviceData.Serial"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="A1B2C3D4E4F6"
                class="text-uppercase"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Key -->
          <validation-provider
            #default="validationContext"
            name="Key"
            rules="required|alpha-num"
          >
            <b-form-group
              label="Chave Secreta"
              label-for="key"
            >
              <b-form-input
                id="key"
                v-model="blankdeviceData.deviceKey"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <!-- <validation-provider
            #default="validationContext"
            name="Email"
            rules="required|email"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="deviceData.email"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider> -->

          <!-- Contact -->
          <!-- <validation-provider
            #default="validationContext"
            name="Contact"
            rules="required"
          >
            <b-form-group
              label="Contact"
              label-for="contact"
            >
              <b-form-input
                id="contact"
                v-model="deviceData.contact"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider> -->

          <!-- Company -->
          <!-- <validation-provider
            #default="validationContext"
            name="Company"
            rules="required"
          >
            <b-form-group
              label="Company"
              label-for="company"
            >
              <b-form-input
                id="company"
                v-model="deviceData.company"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider> -->

          <!-- Asset -->
          <validation-provider
            #default="validationContext"
            name="asset"
            rules="required"
          >
            <b-form-group
              label="Associar à um ativo?"
              label-for="asset"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="deviceData.country"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                value="id"
                label="name"
                :options="assets"
                :clearable="false"
                input-id="assets"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Active -->
          <label>Ativar dispositivo?</label>
          <b-form-group>

            <b-form-radio-group
              id="btn-radios-1"
              v-model="blankdeviceData.isActive"
              button-variant="outline-primary"
              :options="[
                { text: 'Sim', value: true },
                { text: 'Não', value: false },
              ]"
              buttons
              name="radios-btn-default"
            />
          </b-form-group>

          <!-- Plan -->
          <!-- <validation-provider
            #default="validationContext"
            name="Plan"
            rules="required"
          >
            <b-form-group
              label="Plan"
              label-for="plan"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="deviceData.currentPlan"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="planOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="plan"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider> -->

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Adicionar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancelar
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormRadioGroup, BFormCheckboxGroup,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@core/statics/countries'
import deviceModels from '@core/statics/deviceModels'
import assets from '@core/statics/assets'
import store from '@/store'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormRadioGroup,
    BFormCheckboxGroup,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewDeviceSidebarActive',
    event: 'update:is-add-new-device-sidebar-active',
  },
  props: {
    isAddNewDeviceSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    planOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
      deviceModels,
      assets,
    }
  },
  setup(props, { emit }) {
    const blankdeviceData = {
      Name: '',
      Serial: '',
      deviceKey: '',
      Brand: '',
      Model: '',
      Type: '',
      isActive: false,
    }

    const deviceData = ref(JSON.parse(JSON.stringify(blankdeviceData)))
    const resetdeviceData = () => {
      deviceData.value = JSON.parse(JSON.stringify(blankdeviceData))
    }

    const onSubmit = () => {
      store.dispatch('app-device/addDevice', JSON.parse(JSON.stringify(blankdeviceData)))
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-device-sidebar-active', false)
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetdeviceData)

    return {
      blankdeviceData,
      deviceData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-device-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-device-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>

import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchDevices(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('https://api.joule.work/API/Devices', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchDevice(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`https://api.joule.work/API/Devices/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchDeviceEvents(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`https://api.joule.work/API/Events/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addDevice(ctx, deviceData) {
      return new Promise((resolve, reject) => {
        axios
          .post('https://api.joule.work/API/Devices/', deviceData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateDevice(ctx, { id, deviceData }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`https://api.joule.work/API/Devices/${id}`, deviceData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteDevice(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`https://api.joule.work/API/Devices/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },    
  },
}
